<template>
  <div>
    <div class="header">
      <van-nav-bar
        :title="$t('sell.title')"
        left-arrow
        @click-left="$router.go(-1)"
      />
    </div>
    <div class="maincontent">
      <van-form v-model="form" ref="form">
        <van-field
          center
          v-model="form.min"
          :label="$t('sell.min')"
          :placeholder="$t('sell.enter')"
          :rules="[{ required: true, message: $t('该项是必填') }]"
        />
        <van-field
          center
          v-model="form.max"
          :label="$t('sell.max')"
          :placeholder="$t('sell.enter')"
          :rules="[{ required: true, message: $t('该项是必填') }]"
        />
        <van-field
          center
          v-model="form.total"
          :label="$t('sell.total')"
          :placeholder="$t('sell.enter')"
          :rules="[{ required: true, message: $t('该项是必填') }]"
        />
        <div
          style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"
        >
          <span>{{ $t('sell.text') }}{{ balance }}</span>
        </div>
        <van-field
          center
          v-model="form.price"
          :label="$t('sell.price')"
          :placeholder="$t('sell.enter')"
          :rules="[{ required: true, message: $t('该项是必填') }]"
        />
        <van-cell class="language" center :title="$t('sell.unit')">
          <template #extra>
            <van-dropdown-menu active-color="#004ea3">
              <van-dropdown-item v-model="form.unit" :options="unit" />
            </van-dropdown-menu>
          </template>
        </van-cell>
        <van-cell class="language" center :title="$t('sell.contact')">
          <template #extra>
            <van-dropdown-menu active-color="#004ea3">
              <van-dropdown-item v-model="form.payment" :options="payment" />
            </van-dropdown-menu>
          </template>
        </van-cell>
        <van-field
          center
          v-model="form.remark"
          :label="$t('sell.remark')"
          :placeholder="$t('sell.enter')"
        /><van-button class="submitBtn" @click="submit">{{
          $t('common.submit')
        }}</van-button>
      </van-form>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        unit: ''
      },
      unit: [],
      payment: [],
      balance: 0
    }
  },
  created() {
    this.getinfo()
  },
  methods: {
    async getinfo() {
      const { data } = await this.$http.get('/home/trade/sell')
      if (data) {
        if (data.code === 200) {
          this.unit = []
          data.data.unit.forEach(u => {
            this.unit.push({ text: u.unit, value: u.id })
          })
          this.form.unit = this.unit[0].value
          this.balance = +data.data.balance
          this.payment = []
          data.data.contact.forEach(pay => {
            this.payment.push({ text: pay.contact_type, value: pay.id })
          })
          if (this.payment.length < 1) {
            this.$dialog
              .confirm({
                title: this.$t('sell.tips'),
                message: this.$t('sell.tipstext')
              })
              .then(() => {
                this.$router.push('/person/setting/bank')
              })
              .catch(() => {
                this.$router.push('/trade/shopping')
              })
          } else {
            this.form.payment = this.payment[0].value
          }
        }
      }
    },
    submit() {
      this.$refs.form
        .validate()
        .then(async () => {
          if (this.balance < this.form.total) {
            this.$toast.fail(this.$t('sell.toasterr'))
            return false
          }
          const { data } = await this.$http.post('/home/trade/sell', this.form)
          if (data) {
            if (data.code === 200) {
              this.$toast.success(this.$t('sell.toastok'))
              this.form = {}
              this.getinfo()
              this.$router.push('/index')
            } else {
              this.$toast.fail(
                this.getlang(data.msg, localStorage.getItem('lang'))
              )
            }
          }
        })
        .catch(() => {
          // 验证失败
          return false
        })
    }
  }
}
</script>
<style lang="less" scoped>
.maincontent {
  padding: 64px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f7f7f7;
  height: 100vh;
  .van-field {
    font-size: 0.8rem;
  }
  .van-cell {
    height: 4rem;
  }
  /deep/.van-field__control {
    text-align: right;
    padding-right: 1.2rem;
  }
  /deep/.van-field__error-message {
    text-align: right;
  }
  .van-cell__title {
    text-align: left;
  }
  .van-cell__value {
    color: #323233;
  }
  .van-dropdown-menu /deep/.van-dropdown-menu__bar {
    box-shadow: none;
    height: 1rem;
    background: transparent !important;
  }
  .van-dropdown-menu /deep/ .van-dropdown-menu__title {
    color: #212121 !important;
  }
  /deep/.van-dropdown-item {
    margin-top: 1.1rem;
  }
}
.submitBtn {
  margin-top: 20%;
  width: 70%;
  letter-spacing: 0.53333rem;
  height: 4rem;
  font-size: 0.93333rem;
  color: #fff;
  background-color: #004ea3;
  border-radius: 0.53333rem;
  border: none;
  box-shadow: 0 0 1.33333rem #004ea3;
}
</style>
